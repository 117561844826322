/* About.css */

.about {
  text-align: center;
  padding: 40px;
  font-family: "Roboto", sans-serif;
  color: #2c3e50;
  margin-left: 40px;
  margin-right: 40px;
}

.about-hero h1 {
  margin-top: 20px;
  font-size: 3em;
  margin-bottom: 40px;
}

.about-hero p {
  font-size: 1.2em;
}

/* Work and Interests */
.about-content {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.about-card {
  /* background: #f7f7f7; */
  border: 1px solid #2c3e50;
  border-radius: 10px;
  padding: 20px;
  width: 45%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  max-width: 420px;
}

.about-card:hover {
  transform: scale(1.05);
}

.about-card h2 {
  color: #2c3e50;
  margin-bottom: 15px;
}

.about-card p,
.about-card ul {
  font-size: 1.1em;
}

.about-card ul {
  list-style: none;
  padding-left: 0;
}

.about-card ul li {
  margin-bottom: 10px;
}

.about-card ul li a {
  color: #3498db;
  text-decoration: none;
}

.about-card ul li a:hover {
  text-decoration: underline;
}

/* Gallery */
.gallery-title {
  font-size: 2em;
  margin-bottom: 30px;
  color: #34495e;
}

/* Image Gallery */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin: 20px 0;
  justify-content: center;
}

.gallery-item {
  cursor: pointer;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Center the last row when fewer items exist */
.image-gallery > .gallery-item:nth-last-child(1),
.image-gallery > .gallery-item:nth-last-child(2) {
  grid-column: span 2;
  justify-self: center;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.modal-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 15px;
}

.caption {
  font-size: 18px;
  color: #333;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}
