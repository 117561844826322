/* Sidebar container .sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  padding: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
} */

.sidebar h2 {
  color: white;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.sidebar ul {
  color: white;
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: #ffedf3;
  font-size: 18px;
  padding: 12px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative;
}

.sidebar ul li a svg {
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.sidebar ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ffedf3;
  transition: width 0.3s ease;
}

.sidebar ul li a:hover::after {
  width: 100%;
}

.sidebar ul li a:hover {
  color: #ffedf3;
}

.sidebar ul li a:hover svg {
  transform: scale(1.1);
}

.active svg {
  transform: scale(1.15);
}
