.home-container {
  padding: 50px;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content {
  margin-top: -110px;
  max-width: 900px;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
  background: rgba(255, 255, 255, 0.2);
  padding: 40px;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

.home-content h1 {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.home-content p {
  font-size: 20px;
  line-height: 1.8;
  margin-bottom: 30px;
}

.image-and-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-pic {
  width: 250px;
  height: auto;
  border-radius: 50%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.profile-pic:hover {
  transform: scale(1.05); /* Slight zoom-in effect on hover */
}

.cta-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.cta-buttons a {
  background-color: #92736c;
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-buttons a:hover {
  background-color: #a6837b;
  transform: translateY(-3px); /* Lifts button on hover */
}

.cta-buttons a.active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
.download-resume-button {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
