/* src/Contact.css */
.contact {
  text-align: center;
  color: #2c3e50;
  padding: 20px;
}

.contact h1 {
  font-size: 3em;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.contact-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    text-align: center;
  }

  .contact-image {
    margin-bottom: 20px;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.contact-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #2c3e50;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #34495e;
}

.social-media {
  margin-top: 20px;
  align-items: center;
}

.social-media a {
  margin: 0 10px;
  color: #2c3e50;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #4f6f8f;
}

.notification {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 15px 20px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  z-index: 1000;
}

.notification.success {
  background-color: #28a745; /* Green for success */
}

.notification.error {
  background-color: #dc3545; /* Red for error */
}
