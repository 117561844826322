.app {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #92736c;
  padding: 20px;
  position: fixed;
  height: 100%;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  color: #ffedf3;
  font-size: 24px;
  margin-bottom: 20px;
}

.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  color: white;
}

.sidebar nav ul li {
  margin: 10px 0;
  color: white;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #ffedf3;
  font-size: 18px;
  transition: color 0.3s, font-weight 0.3s;
}

.sidebar nav ul li a:hover,
.sidebar nav ul li a.active {
  font-weight: bold;
}

.projects {
  margin-bottom: 20px;
  margin-top: 20px;
}

.content {
  margin-left: 250px;
  padding: 20px;
  flex: 1;
}

.content .element-enter {
  opacity: 0;
  transform: translateY(20px);
}

.content .element-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 600ms ease-in, transform 600ms ease-in;
}
