/* src/Projects.css */
.projects {
  text-align: center;
  padding: 20px;
  color: #2c3e50;
}

.projects h1 {
  font-size: 3em;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project-item {
  width: 300px;
  border: 1px solid #2c3e50;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-image {
  width: 100%;
  height: 200px; /* Set a fixed height */
  object-fit: cover; /* Ensures images are properly cropped and maintain aspect ratio */
  border-radius: 8px;
}

.project-details {
  padding: 15px;
}

.project-details h2 {
  font-size: 20px;
  color: #2c3e50;
  margin: 0 0 10px;
}

.project-details p {
  margin: 0 0 15px;
  color: #2c3e50;
}

.project-details a {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.project-details a:hover {
  background-color: #2980b9;
}

/* Hover effect */
.project-item:hover {
  transform: scale(1.05); /* Slightly enlarge the item */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}
